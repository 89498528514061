import React from "react";
import "./LoaderLogo.css";


    const LogoLoader = () => {
      return (
        <div className="loading">
          {/* Finger 1 */}
          <div className="finger finger-1">
            <div className="finger-item">
              <span></span><i></i>
            </div>
          </div>
    
          {/* Finger 2 */}
          <div className="finger finger-2">
            <div className="finger-item">
              <span></span><i></i>
            </div>
          </div>
    
          {/* Finger 3 */}
          <div className="finger finger-3">
            <div className="finger-item">
              <span></span><i></i>
            </div>
          </div>
    
          {/* Finger 4 */}
          <div className="finger finger-4">
            <div className="finger-item">
              <span></span><i></i>
            </div>
          </div>
    
          {/* Last Finger */}
          <div className="last-finger">
            <div className="last-finger-item"><i></i></div>
          </div>
        </div>
      );
    };
    
    export default LogoLoader;
 