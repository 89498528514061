/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Ayush Palkar",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Ayush Palkar",
    type: "website",
    url: "",
  },
};

//Home Page

const greeting = {
  
  title: "Ayush Palkar",
  logo_name: "Ayush Palkar",
  nickname: "Life Time Student",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1F57z1_AIs72EN6FishB7iRVTnAHEHpKm/view?usp=sharing",
  // portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  GithubProfile: "https://www.linkedin.com/in/ayush-palkar-9984751b6",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/AyushPalkar3",
  // linkedin:https://www.linkedin.com/in/ayush-palkar-9984751b6,
 
  // facebook: "https://www.facebook.com/ayush.palkar.524",
  // twitter: "https://twitter.com/ayushpalkar12",
  // instagram: "https://www.instagram.com/hey__bunny___/",

  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ayush-palkar-9984751b6",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Github",
    link: "https://github.com/AyushPalkar3",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
 
  /*{
    name: "YouTube",
    link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },*/
  {
    name: "Gmail",
    link: "palkar.ayush12@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
 {
    name: "Twitter",
    link:"https://twitter.com/ayushpalkar_in",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  
  {
    name: "Instagram",
    link: "https://www.instagram.com/ayushpalkar.in/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#C0007A", // Reference https://simpleicons.org/?q=instagram
  },
  /* {
    name: "X-twitter",
    link: 
    FontAwesomeIcon :"fa-square-x-twitter",//fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=instagram
  },*/
  {
    name: "Facebook",
    link: "https://www.facebook.com/ayush.palkar.524",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
];

const skills = {
  data: [

    {
      title: "Artificial Intelligence   &  Machine Learning ",
      fileName: "DesignImg",
      skills: [
        "⚡ Strong interest in AI and ML: Demonstrates a genuine passion for emerging technologies and their applications in the field of artificial intelligence and machine learning.",
        "⚡ Aspiring to make a meaningful impact: Expresses a commitment to applying AI/ML knowledge to serve and contribute positively, potentially highlighting a sense of social responsibility or innovation.",
        "⚡ Demonstrates proactive initiative: The willingness to learn and work on AI/ML projects reflects a proactive attitude towards skill development and career growth in this rapidly evolving field.",
      ],
      softwareSkills: [
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos:pytorch-icon",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Spacy",
          fontAwesomeClassname: "simple-icons:spacy",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos:tensorflow",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Caffe2",
          fontAwesomeClassname: "file-icons:caffe2",
          style: {
            color: "#000000",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React.",
        "⚡ Developing mobile applications using Flutter and Java. ",
        "⚡ Creating application backend in Node."
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        /*{
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },*/
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        /*{
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },*/
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Programming",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ I have a passion for programming, and I consider it to be an art form. ",
        "⚡ I'm proficient in multiple languages, including Python, Dart, Dot Net, and Java.",
        "⚡ Having the experience and knowledge in Evaluate the solution for daily Life Problems through Programming",
      ],
      softwareSkills: [
        {
          skillName: "C",
          fontAwesomeClassname: "emojione:letter-c",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "logos:c-plusplus",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "java",
          fontAwesomeClassname: "logos:java",
          style: {
            color: "transperent",
          },
        },
        {
          skillName: "DotNet",
          fontAwesomeClassname: "mdi:dot-net",
          style: {
            
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "logos:dart",
          style: {
            
          },
        },
        /*{
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },*/
      ],
    },
    {
      title: "Operating Systems ",
      fileName: "DataScienceImg",
      skills: ["                     ( Which I am familiar with  )    ",                     
        "⚡ Proficient in multiple operating systems: macOS, Linux, and Windows.",
        "⚡ Versatile in navigating and troubleshooting across diverse computing environments.",
        "⚡ Broad experience with different operating systems enhances adaptability and technical problem-solving skills.",
      ],
      softwareSkills: [
        {
          skillName: "Windows",
          fontAwesomeClassname: "logos:microsoft-windows",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Mac-OS",
          fontAwesomeClassname: "wpf:mac-os",
          style: {
            backgroundColor: "transparent",
          // 
          },
        }, 
        {
          skillName: "Android",
          fontAwesomeClassname: "flat-color-icons:android-os",
          style: {
            backgroundColor: "transparent",
          // 
          },
        },
        
        {
          skillName: "Linux",
          fontAwesomeClassname: "simple-icons:linux",
          style: {
            backgroundColor: "transparent",
            
          },
        },
        {
          skillName: "Kali Linux",
          fontAwesomeClassname: "simple-icons:kalilinux",
          style: {
            backgroundColor: "transperent",
            color: "#557C94",
          },
        },
        
      
      ],
    },
  
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      //profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      //profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      //profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      //profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
     // profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "D.J. Sangvi College of Engineering",
      subtitle: "B.Tech in Information Technolodgy",
      logo_path: "dj.png",
      alt_name: "D J Sangvi",
      duration: "2023 - Present",
      descriptions: [
        "⚡Started to Explore"
      ],
      website_link: "",
    },
    {
      title: "Government Polytechnic Miraj",
      subtitle: "Diploma in Computer Engineering",
      logo_path: "LogoGPM.png",
      alt_name: "GP Miraj",
      duration: "2020 - 2023",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, Visual Basics etc.",
        "⚡ Apart from this, I have completed courses on Android Studio, and Full Stack Development.",
        "⚡ Along with this I have also Studied JavaScript, NPM, ReactJs and NodeJs on Youtube without Certification ",
        "⚡ Also the Collages Official Website is Under Construction by my Industrial training group",
      ],
      website_link: "https://gpmiraj.ac.in/",
    },
    {
      title: "Holy Family Convent High School, Karad",
      subtitle: "Primary and Secondry Schooling",
      logo_path: "HFCHS.png",
      alt_name: "Schooling",
      duration: "2009 - 2020",
      descriptions: [
        "⚡ This Institute emphasize discipline and a structured learning environment, which  helped me developing good study habits and time management skills.",
        "⚡ It often promote values such as respect, responsibility, and compassion, fostering character development alongside academic growth.",
        "⚡Convent schools often prioritize English language proficiency, which can be beneficial for students looking to excel in English-speaking countries or in globally competitive fields.",
        
      ],
      website_link: "",
    },
  ],
  
};

const certifications = {
  certifications: [
   /* {
      title: "Dart",
      subtitle: "- 1 Month",
      logo_path: "Dart_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1iWPEq02nzD8zFTBJSFBS9S7Dr8vL3x_8/view?usp=sharing",
      alt_name: "Stanford University",
      color_code: "#2AB1AC",
    },
    {
      title: "Flutter",
      subtitle: "- 1 Month",
      logo_path: "Flutter_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1K_1F0WJ48WR1WsCJYOVY2dsCYnU0Mo9F/view?usp=sharing",
      alt_name: "deeplearning.ai",
      color_code: "#000000",
    },
    {
      title: "Android Studio",
      subtitle: "- 3 Months",
      logo_path: "AndroidStudio_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "#413F42",
    },
    {
      title: "Web Page Desgning",
      subtitle: "- 2 Months",
      logo_path: "WPD_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#94B49F",
    },*/
   /* {
      title: "Bootstrap",
      subtitle: "- 2 Weeks",
      logo_path: "Bootstrap_logo.jpg",
      certificate_link:
        "https://drive.google.com/file/d/1z1ojOb6Tp4-Te-ldnmI6ztELebgeJJg8/view?usp=sharing",
      alt_name: "Microsoft",
      color_code: "#7952B3",
    },
    {
      title: "WordPress",
      subtitle: "- 2 Weeks",
      logo_path: "WordPress_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1RrANBtHZe6-8vcxuab3558CMmVtMkIfS/view?usp=sharing",
      alt_name: "IBM",
      color_code: "#21759B",
    },
    {
      title: "Node JS and React JS",
      subtitle: "- 1 Month",
      
      logo_path: "JavaScript_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#F7DF1E",
    },
    {
      title: "PHP",
      subtitle: "- 1 Month",
      logo_path: "PHP_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#777BB4",
    },
   {
      title: "NPM",
      subtitle: "- 2 Weeks",
      logo_path: "NPM_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#CB3837",
    },*/
    {
      title: "Data Structure",
      subtitle: "-1 Month ",
      logo_path: "DS_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1gVsjs6GCj87PN0gG7y65rgvwTW4R3udO/view?usp=sharing",
      alt_name: "GCP",
      color_code: "#293462",
    },
    {
      title: "Data Base Management",
      subtitle: "- 1 Month",
      logo_path: "DBMS_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1s1L34l3F_ZmnBOx_LH3p1mzdgj9GVoKb/view?usp=sharing",
      alt_name: "NPTEL",
      color_code: "#FEB139",
    },
    {
      title: "Cyber Security",
      subtitle: "- 3 Months",
      logo_path: "CS_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1dgdjDylT05gP4gH-9-ZY4DqM2aMHrdVV/view?usp=sharing",
      alt_name: "GCP",
      color_code: "#777BB4",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Events and Internships",
  description:
    "I'm pursuing my B.Tech Degree form D.J.Sangvi and completed Diploma from Government college of Engineering,Miraj. As an extrovert I am socially confident person which allows me to communicate with different people in a great manner. Also I am passionate about solving Real life problems by exploring my technical knowledge, as I am too much curious about new technologies. Besides I have positive attitude along with creativity and deligation which leads me to possess leadership quality too!!",
  header_image_path: "experience.svg",
  sections: [
   /* {
      title: "Work",
      experiences: [
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
         // company_url: "https://legatohealthtech.com/",
         // logo_path: "legato_logo.png",
          duration: "June 2020 - PRESENT",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
         // company_url: "https://www.linkedin.com/company/muffito-inc/about/",
        //logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
         // company_url: "https://www.linkedin.com/company/freecopy/about/",
         // logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },*/
    {
      title: "Internships",
      experiences: [
        {
          title: "Full Stack Intern", 
          company: "Happy Visitors",
          company_url: "https://www.happy-visitors.com/#",
          logo_path: "HappyVisitors.jpg",
          duration: "June 2022 - August 2022",
          location: "Kolhapur, Maharashtra",
          description:
            "This is the website development company. Here, I was a Intern for web designing and developer with a passion for creating websites that are easy to understand, visually stunning, and easy to use. I’ve worked on various multitype projects and also I can bring my skillset to any project you have in mind. (2) My experience includes full-stack development, including backend development in Java and Python; frontend development with HTML5, CSS3, jQuery, Bootstrap; and user experience design. (3)  I believe in creating websites that are beautiful but also functional. If you’re looking for someone who has the skills you need to build your vision into reality—and who understands your business goals—then reach out today!",
          color: "#ee3c26",
        },
        {
          title: "Cyber Security Intern",
          company: "Smart Knower",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "SmartKnower.jpg",
          duration: "May 2020 - Jan 2022",
          location: "Work From Home",
          description:
            " This is the EduTech Type of website as well as company  Here I have gained experience in operating systems and hacking tools. In this organisation  I have learned to use these skills to defend myself from attacks and protect my network from attacks. I have also been able to use this knowledge to help others learn about security and how to secure their own systems.",

            
          color: "#0071C5",
        },
      ],
      
    },
    {
      title: "Events",
      experiences: [
       
        {
          title: "ProTech",
          company: "Symbiosis Collage of Engineering",
          company_url: "https://www.sitpune.edu.in/",
          logo_path: "SIT_logo.png",
          duration: "July 2022",
          location: "Pune, Maharashtra",
          description:
            "This technical entrepreneurship event was a gathering or competition that focuses on fostering innovation, creativity, and entrepreneurial spirit within the technology and startup community. It typically bought together aspiring entrepreneurs, tech enthusiasts, and industry experts. We had opportunity to pitch our tech-based business ideas, receive feedback from mentors and judges, and compete for prizes or investment opportunities.",
          color: "#0071C5",
        },
        {
          title: "StartoVenture", 
          company: "Walchand collage of Engineering",
          company_url: "http://www.walchandsangli.ac.in/",
          logo_path: "Walchand_logo.png",
          duration: "May 2022",
          location: "Sangli, Maharashtra",
          description:
            " We Participants in pitching events typically had to prepare a concise yet compelling presentation that covers key aspects of our business idea, such as market analysis, revenue model, competitive advantage, and growth strategy. It must engage the audience and judges to make a persuasive case for the idea.",
          color: "#ee3c26",
        },
      ],
    },
    /*{
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },*/
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools in Customisable products for local as well as reputed businesses. My best experience is to create Multifunctional  websites for different purposes also to deploy and maintain them on different platforms.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "",
  description:
    " Fetching data from GitHub........",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "Ap1.png",
    description:[
      "I am dedicated to provide top-notch IT solutions to local businesses and institutes like: Mobile Application, Website and Softwares.",
      "Your success is my priority, and I look forward to partnering with you.",
      "  Thank you for considering my services. I can't wait to hear from you!"
    ],
    },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment and to make peolpe omniscient about the latest technology and new developments in the field of Engineering, as well as Medical. I like to blazon powerful lessons in a very professional way to Desseminate about the things that are happening in the world of science and technology.",
    link: "https://slidingmotion.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Gagangiri Heights Side to NH-4 Malkapur Karad, Maharashtra",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps/place/Suyash+Medicals+and+Supermarket/@17.2624722,74.1778043,19.75z/data=!4m12!1m6!3m5!1s0x3bc1834d20984e27:0xb35802a148efb60b!2sSuyash+Medicals+and+Supermarket!8m2!3d17.2624451!4d74.1780364!3m4!1s0x3bc1834d20984e27:0xb35802a148efb60b!8m2!3d17.2624451!4d74.1780364",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 7058951159",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
